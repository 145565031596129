import { Environment } from '@bling-fe/shared/env';

export const environment: Environment = {
  name: 'development',
  sentry: '',
  production: false,
  apiDomain: 'https://www.admin.dev.bling.app/api/v1/',
  tenant: 'IHZ',
  votingAppUrl: '',
  washMasterUrl: '',
  websocketUrl: 'wss://www.admin.dev.bling.app/websocket',
  gaMeasurementId: '',
};
