import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { IonicModule } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@flink-legacy/shared/directives/directives.module';
import { UiNotificationsBadgeComponent } from '@bling-fe/shared/ui-components/ui-notifications-badge';
import { MainMenuService } from '../main-menu.service';
import { MainMenuItemComponent } from './main-menu-item.component';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { Observable, map } from 'rxjs';
import { User } from '@flink-legacy/core/declarations/user.interface';

@Component({
  selector: 'bling-fe-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SvgIconComponent,
    RouterModule,
    TranslateModule,
    DirectivesModule,
    UiNotificationsBadgeComponent,
    MainMenuItemComponent,
  ],
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  private store = inject(Store);
  desktopMenuItems$ = inject(MainMenuService).items();

  currentTenant$ = this.store.pipe(select(getCurrentTenant));
  currentMembershipActive$: Observable<boolean> = this.store.pipe(
    select(getCurrentUser),
    map((user: User) => user?.current_membership?.status == 'active')
  );
}
