import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEYS } from '@flink-legacy/core/declarations/storage-keys.enum';

export const onboardingCompletedGuard: CanActivateFn = async (_, __) => {
  const router = inject(Router);
  const isDone = await Preferences.get({ key: STORAGE_KEYS.ONBOARDING_DONE });
  return isDone.value === 'true' ? true : router.createUrlTree(['/onboarding']);
};
