<ih-modal-header
  [title]="props.modalTitle | translate"
  (dismiss)="dismiss()"
></ih-modal-header>

<bling-fe-tabs-navigation-modal-item
  *ngFor="let item of props.tabs"
  [item]="item"
  (click)="dismiss()"
  class="d-block border-bottom"
/>
