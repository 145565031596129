<ih-modal-header
  [title]="'IHZ_MEMBER.UI_NEW_MODAL.TITLE' | translate"
  (dismiss)="dismiss()"
></ih-modal-header>

<div class="content">
  <a
    *ngFor="let item of items"
    class="icon-link"
    [routerLink]="item.url"
    [queryParams]="item.queryParams"
    (click)="dismiss()"
  >
    <svg-icon
      [key]="item.icon"
      size="xl"
    ></svg-icon>
    <span>
      {{ item.title | translate }}
    </span>
  </a>
</div>
