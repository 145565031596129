import { LoadingService } from './loading.service';
import { EMPTY, catchError, concatMap, map, Observable, from, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@flink-legacy/core/error-handler/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ResolverService {
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  createResolver<T>(data$: Observable<T>, itemType: string): Observable<T> {
    const loading$ = from(this.loadingService.show());

    return loading$.pipe(
      concatMap(() => data$),
      map(res => res as T),
      tap(() => this.loadingService.dismiss()),
      catchError(e => {
        if (e.status === 404) {
          this.router.navigate(['/not-found'], {
            queryParams: { type: itemType },
            skipLocationChange: true,
          });
        } else {
          this.errorHandlerService.handleHttpError(e);
          this.router.navigate(['/']);
        }
        return EMPTY;
      })
    );
  }
}
