import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IhzMemberUiLayoutAppLayoutComponent } from '@bling-fe/ihz-member/ui-layout/app-layout';
import { BasicLayoutComponent } from '@flink-legacy/core/layout/basic-layout/basic-layout.component';
import { ReservationsEnabledGuard } from '@flink-legacy/shared/guards/reservations-enabled.guard';
import {
  TenantSelectedGuard,
  tenantSelectedGuard,
} from '@flink-legacy/shared/guards/tenant-selected.guard';
import {
  AuthenticatedGuard,
  authenticatedGuard,
} from '@flink-legacy/shared/guards/authenticated.guard';
import { RedirectIfLoggedIn } from '@flink-legacy/shared/guards/redirect-if-logged-in.guard';
import {
  TermsAcceptedGuard,
  termsAcceptedGuard,
} from '@flink-legacy/shared/guards/terms-accepted.guard';
import {
  externalChangelogConfiguredGuard,
  externalHelpPageConfiguredGuard,
  externalPrivacyPolicyConfiguredGuard,
  externalTermsOfUseConfiguredGuard,
  externalTermsAndConditionConfiguredGuard,
} from '@flink-legacy/shared/guards/static-links.guard';
import { ResetPasswordGuard } from '@flink-legacy/shared/guards/reset-password.guard';
import { ProfileResolver } from '@flink-legacy/pages/profile/profile.resolver';
import { instantMessagingEnabledGuard } from '@flink-legacy/shared/guards/instant-messaging-enabled.guard';
import {
  IhzMemberFeatureOnboardingComponent,
  onboardingCompletedGuard,
} from '@bling-fe/ihz-member/feature-onboarding';
import { RedirectIfTermsAccepted } from '@flink-legacy/shared/guards/redirect-if-terms-accepted.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: IhzMemberUiLayoutAppLayoutComponent,
    canActivate: [onboardingCompletedGuard],
    children: [
      {
        path: 'home',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-news').then(
            m => m.FeatureNewsComponent
          ),
      },
      {
        path: 'member',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-community').then(
            m => m.IhzMemberFeatureCommunityComponent
          ),
      },
      {
        path: 'services',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/feature-services').then(
            m => m.IhzMemberFeatureServicesModule
          ),
      },
      {
        path: 'my-profile',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-profile').then(
            m => m.IhzMemberFeatureMyProfileComponent
          ),
      },
      {
        path: 'my-profile/delete-account',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/my-profile-delete-account/my-profile-delete-account.module'
          ).then(m => m.MyProfileDeleteAccountPageModule),
      },
      {
        path: 'calendar',
        canActivateChild: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/events/feature-events').then(
            m => m.FeatureEventsModule
          ),
      },
      {
        path: 'my-tenant',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages//my-tenant/my-tenant.module').then(
            m => m.MyTenantPageModule
          ),
      },
      {
        path: 'new',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/add-new/add-new.module').then(
            m => m.AddNewPageModule
          ),
      },
      {
        path: 'edit/profile',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-edit-profile').then(
            m => m.IhzMemberFeatureEditProfileComponent
          ),
      },
      {
        path: 'edit',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/edit/edit.module').then(
            m => m.EditModule
          ),
      },
      {
        path: 'under-construction',
        loadChildren: () =>
          import(
            '@flink-legacy/pages/under-construction/under-construction.module'
          ).then(m => m.UnderConstructionPageModule),
      },
      {
        path: 'post/:id',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/post-detail/post-detail.module').then(
            m => m.PostDetailPageModule
          ),
      },
      {
        path: 'event/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/events/feature-event-detail').then(
            m => m.FeatureEventDetailModule
          ),
      },
      {
        path: 'ticket/:id',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/repair-message-detail/repair-message-detail.module'
          ).then(m => m.RepairMessageDetailPageModule),
      },
      {
        path: 'hourly-reservable/:type/:id',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/hourly-reservable-detail/hourly-reservable-detail.module'
          ).then(m => m.HourlyReservableDetailPageModule),
      },
      {
        path: 'hourly-reservation/:type/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/hourly-reservation-detail/hourly-reservation-detail.module'
          ).then(m => m.HourlyReservationDetailPageModule),
      },
      {
        path: 'daily-reservable/:type/:id',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/daily-reservable-detail/daily-reservable-detail.module'
          ).then(m => m.DailyReservableDetailPageModule),
      },
      {
        path: 'daily-reservation/:type/:id',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          ReservationsEnabledGuard,
          TermsAcceptedGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/daily-reservation-detail/daily-reservation-detail.module'
          ).then(m => m.DailyReservationDetailPageModule),
      },
      {
        path: 'profile/:id',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/feature-profile').then(
            m => m.ihzMemberFeatureUserProfileRoutes
          ),
        resolve: { user: ProfileResolver },
      },
      {
        path: 'instant-messaging/:id',
        canActivateChild: [
          TenantSelectedGuard,
          AuthenticatedGuard,
          TermsAcceptedGuard,
          instantMessagingEnabledGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/instant-messaging/instant-messaging.module'
          ).then(m => m.InstantMessagingPageModule),
      },
      {
        path: 'group',
        canActivateChild: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/channels/feature-channel').then(
            m => m.IhzMemberFeatureChannelModule
          ),
      },
      {
        path: 'changelog',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
          externalChangelogConfiguredGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/changelog/changelog.module').then(
            m => m.ChangelogPageModule
          ),
      },
      {
        path: 'help',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
          externalHelpPageConfiguredGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/help/help.module').then(
            m => m.HelpPageModule
          ),
      },
      {
        path: 'privacy-policy',
        canActivateChild: [
          AuthenticatedGuard,
          TenantSelectedGuard,
          TermsAcceptedGuard,
          externalPrivacyPolicyConfiguredGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/privacy-policy/privacy-policy.module'
          ).then(m => m.PrivacyPolicyPageModule),
      },
      {
        path: 'terms-of-use',
        canActivateChild: [
          TenantSelectedGuard,
          externalTermsOfUseConfiguredGuard,
        ],
        loadChildren: () =>
          import('@flink-legacy/pages/terms-of-use/terms-of-use.module').then(
            m => m.TermsOfUsePageModule
          ),
      },
      {
        path: 'terms-and-conditions-static',
        canActivateChild: [
          TenantSelectedGuard,
          externalTermsAndConditionConfiguredGuard,
        ],
        loadChildren: () =>
          import(
            '@flink-legacy/pages/terms-and-conditions/terms-and-conditions.module'
          ).then(m => m.TermsAndConditionsPageModule),
      },
      {
        path: 'membership-plan',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-membership-plan').then(
            m => m.FeatureMembershipPlanComponent
          ),
      },
      {
        path: 'invoices',
        canActivate: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadComponent: () =>
          import('@bling-fe/ihz-member/feature-invoices').then(
            m => m.FeatureInvoicesComponent
          ),
      },
      {
        path: 'room-booking',
        canActivateChild: [
          authenticatedGuard,
          tenantSelectedGuard,
          termsAcceptedGuard,
        ],
        loadChildren: () =>
          import('@bling-fe/ihz-member/room-booking/feature-room-booking').then(
            m => m.FeatureRoomBookingModule
          ),
      },
      {
        path: 'not-found',
        loadComponent: () =>
          import('@bling-fe/shared/feature-not-found').then(
            m => m.SharedFeatureNotFoundComponent
          ),
      },
    ],
  },
  {
    path: 'login',
    component: BasicLayoutComponent,
    canActivateChild: [
      TenantSelectedGuard,
      RedirectIfLoggedIn,
      onboardingCompletedGuard,
    ],
    loadChildren: () =>
      import('@flink-legacy/pages/login/login.module').then(
        m => m.LoginPageModule
      ),
  },
  {
    path: 'reset-password',
    component: BasicLayoutComponent,
    canActivateChild: [TenantSelectedGuard, ResetPasswordGuard],
    loadChildren: () =>
      import('@flink-legacy/pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'email-confirmation',
    component: BasicLayoutComponent,
    canActivateChild: [TenantSelectedGuard],
    loadChildren: () =>
      import(
        '@flink-legacy/pages/email-confirmation/email-confirmation.module'
      ).then(m => m.EmailConfirmationPageModule),
  },
  {
    path: 'onboarding',
    component: BasicLayoutComponent,
    children: [
      { path: '', loadComponent: () => IhzMemberFeatureOnboardingComponent },
    ],
  },
  {
    // TODO https://trello.com/c/soF4Vn3W/2061-rename-terms-and-conditions-route-to-accept-terms
    path: 'terms-and-conditions',
    component: BasicLayoutComponent,
    canActivateChild: [
      AuthenticatedGuard,
      TenantSelectedGuard,
      RedirectIfTermsAccepted,
    ],
    loadChildren: () =>
      import('@flink-legacy/pages/accept-terms/accept-terms.module').then(
        m => m.AcceptTermsPageModule
      ),
  },
  {
    path: 'privacy-policy-public',
    component: BasicLayoutComponent,
    canActivateChild: [],
    loadChildren: () =>
      import('@flink-legacy/pages/privacy-policy/privacy-policy.module').then(
        m => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'payment',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('@flink-legacy/payment-status/payment-status.module').then(
        m => m.PaymentSuccessfulPageModule
      ),
  },
  // default path is home
  {
    path: '**',
    component: IhzMemberUiLayoutAppLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@bling-fe/shared/feature-not-found').then(
            m => m.SharedFeatureNotFoundComponent
          ),
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
