import {
  Component,
  ElementRef,
  EnvironmentInjector,
  NgZone,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { LoadCurrentNewsfeedFiltersAction } from '@flink-legacy/core/states/newsfeed-state/newsfeed.actions';
import { LoadCurrentTenantAction } from '@flink-legacy/core/states/tenant-state/tenant.actions';
import { AuthenticationService } from '@flink-legacy/shared/services/authentication.service';
import { HealthCheckService } from '@flink-legacy/shared/services/health-check.service';
import { ThemingService } from '@flink-legacy/shared/services/theming.service';
import { PushNotificationService } from '@flink-legacy/shared/services/push-notification.service';
import { TextZoom } from '@capacitor/text-zoom';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {
  handleResize,
  setPageTransitionAnimated,
} from '@flink-legacy/core/states/layout-state/layout.actions';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '@flink-legacy/shared/services/loading.service';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  fromEvent,
  map,
  of,
  startWith,
} from 'rxjs';

@Component({
  selector: 'ihz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private element: ElementRef<HTMLElement>,
    private platform: Platform,
    private checkHealthService: HealthCheckService,
    private store: Store,
    private themingService: ThemingService,
    private authenticationService: AuthenticationService,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private zone: NgZone,
    private loadingService: LoadingService,
    public environmentInjector: EnvironmentInjector
  ) {
    this.initializeApp();
    this.showLoadingUntilNavigationFinished();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.store.dispatch(new LoadCurrentTenantAction());
      this.store.dispatch(new LoadCurrentNewsfeedFiltersAction());
      this.checkHealthService.checkVersion();
      this.themingService.init();
      this.authenticationService.init();
      this.pushNotificationService.init();
      this.setPreferedZoom();
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          const url = new URL(event.url);
          const path = `${url.pathname}${url.search}${url.hash}`;
          this.router.navigateByUrl(path);
        });
      });
      this.setPageTransitions();
      this.watchWindowResize();
    });
  }

  private showLoadingUntilNavigationFinished() {
    const firstNavigationEnd = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      first()
    );

    firstNavigationEnd
      .pipe(
        map(() => 'dismissLoading'),
        startWith('showLoading'),
        // show loading only if navigation end takes more than 200ms
        debounceTime(200),
        concatMap(res =>
          res === 'showLoading' ? this.loadingService.show() : of(res)
        ),
        // loadingService.dismiss() has to be called always after loadingService.show() finished
        concatMap(res =>
          res === 'dismissLoading' ? this.loadingService.dismiss() : of(res)
        )
      )
      .subscribe();
  }

  // sets base font size for ios, where it doesn't work out-of-the-box
  private async setPreferedZoom() {
    if (Capacitor.isNativePlatform()) {
      const zoom = await TextZoom.getPreferred();
      document.querySelector('html').style.fontSize = `${zoom.value * 100}%`;
    }
  }

  private setPageTransitions() {
    if (Capacitor.isNativePlatform()) {
      this.store.dispatch(setPageTransitionAnimated({ animated: true }));
    } else {
      this.store.dispatch(setPageTransitionAnimated({ animated: false }));
    }
  }

  /**
   * Observe window.resize event and update layout store accordingly.
   */
  private watchWindowResize() {
    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(startWith(null), debounceTime(300), distinctUntilChanged())
        .subscribe(() => {
          this.zone.run(() => {
            this.store.dispatch(
              handleResize({ width: this.element.nativeElement.offsetWidth })
            );
          });
        });
    });
  }
}
