<!--
    Desktop layout uses ion-content with list of links
-->
<ng-container *flDesktopOnly>
  <ion-content class="desktop flex--center">
    <ng-container *ngTemplateOutlet="desktop"></ng-container>
  </ion-content>
</ng-container>

<ng-template #desktop>
  <ng-container *ngIf="desktopMenuItems$ | async as desktopMenuItems">
    <ion-list lines="none">
      <ion-item class="app-logo">
        <a [routerLink]="['/']">
          <!-- IHZ LOGO. Directly pasted SVG, so we're able to change colour -->
          <svg
            class="app-logo-img"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44 44H0V0H44V44ZM1.69231 42.3077H42.3077V1.69231H1.69231V42.3077Z"
              fill="currentColor"
            />
            <path
              d="M37.9599 32.7891C37.9599 34.754 36.5721 35.9595 34.2973 35.9595H29.4097V24.5134H34.1163C36.3911 24.5134 37.797 25.6406 37.797 27.491C37.797 28.9797 36.9281 29.8597 35.6067 30.1069C36.9281 30.2395 37.9599 31.2038 37.9599 32.7891ZM32.0587 29.2088H33.531C34.5447 29.2088 35.1481 28.6844 35.1481 27.8164C35.1481 26.9485 34.5567 26.4241 33.531 26.4241H32.0587V29.2088ZM35.2929 32.4455C35.2929 31.5956 34.6533 31.0411 33.7059 31.0411H32.0526V33.9523H33.7059C34.6895 33.9523 35.2929 33.3978 35.2929 32.4455Z"
              fill="currentColor"
            />
            <path
              d="M17.8003 32.0899V24.5134H20.4312V31.9211C20.4312 33.2291 21.2156 33.9644 22.4103 33.9644C23.6232 33.9644 24.3895 33.2291 24.3895 31.9211V24.5134H27.0384V32.0838C27.0384 34.748 25.2222 36.2548 22.4103 36.2548C19.5985 36.2548 17.8003 34.754 17.8003 32.0899Z"
              fill="currentColor"
            />
            <path
              d="M6.18485 24.5134H8.83378V29.1425H12.7921V24.5134H15.441V35.9655H12.7921V31.4148H8.83378V35.9655H6.18485V24.5134Z"
              fill="currentColor"
            />
            <path
              d="M34.9731 18.7633H33.6577V17.4553H37.8091V18.7633H36.4997V22.5666H34.9731V18.7633Z"
              fill="currentColor"
            />
            <path
              d="M27.2134 20.011C27.2134 18.4258 28.3658 17.2986 30.0433 17.2986C31.6363 17.2986 32.6681 18.0762 32.7948 19.4203H31.1898C31.0872 18.9562 30.6769 18.6488 30.0493 18.6488C29.2589 18.6488 28.7943 19.1732 28.7943 20.011C28.7943 20.8488 29.2589 21.3732 30.0493 21.3732C30.6769 21.3732 31.0872 21.0658 31.1898 20.6017H32.7948C32.6681 21.9458 31.6363 22.7233 30.0433 22.7233C28.3658 22.7233 27.2134 21.5962 27.2134 20.011Z"
              fill="currentColor"
            />
            <path
              d="M24.8722 21.9759H23.2249L23.062 22.5666H21.4871L23.2309 17.4614H24.8722L26.61 22.5666H25.0351L24.8722 21.9759ZM24.5825 20.8729L24.0515 18.8899L23.5206 20.8729H24.5825Z"
              fill="currentColor"
            />
            <path
              d="M21.1432 19.36C21.1432 20.457 20.3407 21.2586 19.2486 21.2586H18.3012V22.5666H16.7806V17.4614H19.2486C20.3407 17.4553 21.1432 18.269 21.1432 19.36ZM19.6046 19.36C19.6046 18.9441 19.3149 18.6367 18.9348 18.6367H18.3012V20.0833H18.9288C19.3149 20.0833 19.6046 19.7759 19.6046 19.36Z"
              fill="currentColor"
            />
            <path
              d="M9.25614 17.4553H11.187L12.2731 20.7945L13.3532 17.4553H15.2358V22.5666H13.7756V19.3238L12.8403 22.5666H11.718L10.7164 19.3118V22.5666H9.25614V17.4553Z"
              fill="currentColor"
            />
            <path
              d="M7.69939 17.4553H6.18485V22.5606H7.69939V17.4553Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </ion-item>
      <bling-fe-main-menu-item
        *ngIf="currentMembershipActive$ | async"
        [item]="desktopMenuItems.NEW"
      />
      <bling-fe-main-menu-item [item]="desktopMenuItems.HOME" />
      <bling-fe-main-menu-item [item]="desktopMenuItems.CALENDAR" />
      <bling-fe-main-menu-item [item]="desktopMenuItems.COMMUNITY" />
      <bling-fe-main-menu-item [item]="desktopMenuItems.ROOM_BOOKING" />
      <bling-fe-main-menu-item [item]="desktopMenuItems.SERVICES" />
    </ion-list>
    <ion-list
      lines="none"
      class="bottom-list"
    >
      <bling-fe-main-menu-item [item]="desktopMenuItems.HELP" />
      <bling-fe-main-menu-item [item]="desktopMenuItems.MY_PROFILE" />
    </ion-list>
  </ng-container>
</ng-template>
