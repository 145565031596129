import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type CookiebotConsents = {
  marketing: boolean;
  statistics: boolean;
  preferences: boolean;
  necessary: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private window: any = window;

  consents$ = new Subject<CookiebotConsents>();

  init() {
    window.addEventListener('CookiebotOnAccept', () => {
      this.consents$.next({
        marketing: this.marketingConsent,
        statistics: this.statisticsConsent,
        preferences: this.preferencesConsent,
        necessary: this.necessaryConsent,
      });
    });
    window.addEventListener('CookiebotOnDecline', () => {
      this.consents$.next({
        marketing: this.marketingConsent,
        statistics: this.statisticsConsent,
        preferences: this.preferencesConsent,
        necessary: this.necessaryConsent,
      });
    });
  }

  get marketingConsent() {
    return this.window.Cookiebot?.consent?.marketing;
  }
  get statisticsConsent() {
    return this.window.Cookiebot?.consent?.statistics;
  }
  get preferencesConsent() {
    return this.window.Cookiebot?.consent?.preferences;
  }
  get necessaryConsent() {
    return this.window.Cookiebot?.consent?.necessary;
  }
}
