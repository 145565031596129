import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';

/**
 * These guards should be only used on pages that do not lead further or are NOT part of a flow.
 *
 * For example, we have GTC page that is shown to the user when he logs in for the first time.
 * If there is external link guard, user is taken out from the app, which is not very nice.
 */
const checkExternalLink = (link: string) => {
  const tenantStore = inject(Store);

  return tenantStore.select(getCurrentTenant).pipe(
    filter(x => x !== null && x !== 'loading'),
    map(tenant => tenant as Tenant),
    map(async tenant => {
      if (!tenant.tenant_setting[link]) return true;

      return (window.location.href = tenant.tenant_setting[link]);
    })
  );
};

export const externalTermsAndConditionConfiguredGuard = () => {
  return checkExternalLink('terms_and_conditions_url');
};

export const externalTermsOfUseConfiguredGuard = () => {
  return checkExternalLink('terms_of_use_url');
};

export const externalHelpPageConfiguredGuard = () => {
  return checkExternalLink('help_page_url');
};

export const externalChangelogConfiguredGuard = () => {
  return checkExternalLink('change_log_url');
};

export const externalPrivacyPolicyConfiguredGuard = () => {
  return checkExternalLink('privacy_policy_url');
};
